.h1 {
  margin-bottom: 0;
}

.statsTable {
  font-size: 13px;

  td {
    padding: 5px 10px;
    border: 1px solid #eee;
  }
}

.chart {
  :global {
    .recharts-line-dot[cy="360"],
    .recharts-label[y="350"] {
      display: none;
    }
  }
}
