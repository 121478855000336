.linkContainer {
  position: relative;
  padding-right: 40px;

  
  .linkWrapper,
  button {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 35px;
    font-size: 16px;
    cursor: pointer;
  }

  .linkWrapper {
    background-color: #f9f9f9;
    font-size: 12px;
    line-height: 1.2;
    max-width: 100%;
    padding: 10px 10px;
    word-break: break-word;
  }
}
