.detailTable {
  font-size: 13px;

  td:first-child {
    font-weight: 700;
    padding-right: 5px;
  }
}

.select {
  width: 49%;
}
