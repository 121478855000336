.container {
  td:last-child {
    word-break: break-word;
  }
}

.filterContainer {
  $drop-width: 150px;
  $view-width: 90px;

  position: relative;
  padding-left: $drop-width + 5px;
  padding-right: $view-width + 5px;

  .filterDrop {
    position: absolute;
    top: 0;
    left: 0;
    width: $drop-width;
  }

  .filterView {
    position: absolute;
    top: 0;
    right: 0;
    width: $view-width;

    button {
      width: 50%;
      min-width: 0;
      height: 45px;
    }
  }
}
