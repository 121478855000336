.container {
  border: 1px solid #cfcfcf;
  display: inline-block;
}

.tab {
  background-color: #fff;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #bababa;
  border-top: none;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  height: 30px;
  min-width: 120px;
  outline: none;
  transition: background-color 250ms;

  &:hover { background-color: rgba(166, 198, 230, 0.3); }

  &:last-child {
    border-right: none;
  }

  &.active {
    background-color: rgb(166, 198, 230);
    
    &:hover { background-color: rgb(166, 198, 230) }
  }
}
