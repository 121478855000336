.copy {
  border: none;
  background: none;
  color: #005eb8;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    transform: scale(1.2);
  }
}