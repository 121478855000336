@import '../../assets/styles/colors';

.select {
  width: 49%;
}

.showDisabled {
  color: $color-text-accent;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    text-decoration: inherit;

    &:first-child {
      margin-right: 5px;
      margin-left: 1px;
    }
  }
}

.showVersionsCheck {
  vertical-align: middle;
}

.showVersionsLabel {
  font-size: 12px;
  font-style: italic;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
