/* Colors */
$color-blue: #005eb8;
$color-lightBlue: #a6c6e6;
$color-gray: #9b9b9b;
$color-lightGray: #d4d4d4;
$color-otherLightGray: #d5d5d5;
$color-green: #699f42;
$color-orange: #f99c34;
$color-red: #d0021b;

// Font colors
$color-text-dark: #4a4a4a;
$color-text-light: #fff;
$color-text-accent: #4a90e2;
