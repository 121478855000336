.summaryTable {
  font-size: 13px;
  margin-bottom: 20px;

  // tr:not(:last-child) { border-bottom: 1px solid #ddd; }
  tr {
    border-bottom: 2px solid transparent;
  }
  tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom-color: #ddd;
  }

  td {
    padding: 5px 10px 3px;
  }

  td:first-child {
    font-weight: 700;
    // padding-left: 0;
  }

  td:nth-child(3) { padding-left: 0; }
}