.container {
  h1 {
    margin: 30px 0 15px;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 24px;
      opacity: .6;
      transition: opacity .2s;

      &:hover { opacity: 1; }
    }

    &:first-child { margin-top: 0; }
  }
}

.select {
  width: 49%;
}

.linkContainer {
  position: relative;
  padding-right: 40px;

  
  .linkWrapper,
  button {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 36px;
    width: 35px;
    font-size: 16px;
    cursor: pointer;
    
    &:not(:hover) { color: inherit; }

    &:hover {
      transform: none;
      border-color: #005eb8;
    }
  }

  .linkWrapper {
    background-color: #f9f9f9;
    font-size: 12px;
    line-height: 1.2;
    max-width: 100%;
    padding: 10px 10px;
    word-break: break-word;
  }
}
