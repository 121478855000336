.container {
  background-color: white;
  display: inline-block;
  border: 1px solid #ccc;
  height: 32px;
  line-height: 30px;

  a {
    display: inline-block;
    border: none;
    color: #000;
    width: 2em;
    cursor: pointer;
    border-radius: 0;
    text-align: center;

    &:hover {
      background-color: #efefef;
    }

    &.disabled {
      color: #d7d7d7;
      cursor: default;

      &:hover { background-color: #fff; }
    }
  }
}
