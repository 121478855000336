.container {
  color: #0848a9;
  margin: 0 auto;
  min-height: 100%;
  text-align: center;
  padding: 60px;

  h1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 0.9;
    margin: 50px auto 30px;
  }

  h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 0.9;
    margin: 0;
    padding: 0;
  }

  .componentStack {
    text-align: left;
    padding: 20px;
    background-color: #eee;
    border: 1px solid #4a4a4a;
    color: #4a4a4a;
    max-width: 500px;
    margin: 10px auto;
  }
}
